#hero {
   margin-top: 50px;
   position: relative;
   display: block;
   @include tablet {
      margin-top: 90px;
   }
   @include tablet-wide {
      margin-top: 90px;
   }
}

.hero-layer {
   display: block;
   overflow: hidden;
   background: $white;
   padding: 0rem;
   &.structure-5050 {
      display: block;
      @include tablet {
         display: flex;
         & .hero-item-container {
            bottom: 0rem !important;
         }
         & .hero-item-blurb {
            width: 100% !important;
            padding: 0 !important;
            transform: scale(0.8) !important;
         }
      }
      @include desktop {
         & .hero-item-container {
            bottom: 3rem !important;
         }
         & .hero-item-blurb {
            width: 90% !important;
            padding: 0 3rem !important;
            transform: scale(0.9) !important;
         }
      }
      @include md {
         & .hero-item-container {
            bottom: 4rem !important;
         }
         & .hero-item-blurb {
            width: 80% !important;
            padding: 0 4rem !important;
            transform: scale(1.0) !important;
         }
      }
      @include lg {
         & .hero-item-blurb {
            width: 70% !important;
         }
      }
   }
   @include tablet {
      background: $clear;
      padding: 0;
   }
}

.hero-item {
	background-color: $darkgray;
   @include size(100%, auto);	
	position: relative;
   margin: 0 auto;
   transition: $slow;
   overflow: hidden;
	@include tablet {
		@include size(100%, 500px);
      overflow: visible;
	}
   @include tablet-wide {
		@include size(100%, 600px);
	}
   @include desktop {
		@include size(100%, 750px);
      max-width: $desktop-medium;
	}
   @include md {
   	@include size(100%, 800px);
      max-width: $desktop-large;
	}
   &-container {
      width: 100%;
      position: relative;
      bottom: 0;
      left: 0;
      display: block;
      transition: $slow;
      @include tablet {
         position: absolute;
         bottom: 4rem !important;
         -webkit-box-shadow: none;
         box-shadow: none;          
      }
      @include tablet-wide {
         bottom: 5rem !important;
      }
      @include desktop {
         bottom: 6rem !important;
      }
   }
   &-blurb {
		color: $text;
      max-width: $max-width;
		font-size: 1.1rem;
		line-height: 1.2;
		opacity: 0;	
      background: $clear;
      padding: 1.5rem 2rem;
      width: 100%;
      z-index: 100;
      position: relative;
      text-align: left;
      transform: translateY(4rem);
      transition: $slow;
      transition-delay: 1.0s;
      &.reveal {
         opacity: 1;
         transform: translateY(0);
      }
      @include tablet {
         width: 65%;
         padding: 0 4rem !important;
      }
		@include tablet-wide {
         background: $clear;
			font-size: 1.1rem;
         padding: 0 5rem !important;
		}		
   	@include desktop {
			font-size: 1.2rem;
         padding: 0 6rem !important;
		}		
		@include md {
			font-size: 1.2rem;
         padding: 0 10% !important;
		}		
		p {
			margin-bottom: 0;
		}
      &-pre-headline {
   		background: none;
   		color: $yellow;
   		font-family: $nav;
         font-size: 0.9rem;
   		display: inline-block;
   		margin: 0 auto;
   		text-transform: uppercase;
   		padding: .2rem .4rem;
   		margin-bottom: 0.75rem;
   		opacity: 1;
   		position: relative;
   		z-index: 200;
         left: -.4rem;
         @extend %headline5;
         @include tablet {
            font-size: 1.1rem !important;
         }
         &::after {
            content: "";
            width: 102%;
            height: 2px;
            position: absolute;
            bottom: -1px;
            left: -1%;
            z-index: -1;
            background: $yellow; 
            @include tablet {
               height: 4px;
               width: 104%;
               left: -2%;
               bottom: -2px;
            }
         }	  
   	}
   	&-headline {
   		color: $white;
         font-family: $headline;
   		line-height: 1;
   		opacity: 1;
         position: relative;
         @extend %headline1;
         font-size: 1.35rem;
         margin-bottom: 1rem;
         &-link {
            color: $white;
         }       
         @include tablet {
            font-size: 2.35rem !important;
            margin-bottom: 0;
         }
         @include tablet-wide {
            font-size: 2.65rem !important;
         }
         @include desktop {
            font-size: 3rem !important;
         }
   	}
      &-text {
         display: none;
      	max-width: $max-width;
   		padding: 1rem 0 1.5rem;
   		width: 100%;
   		z-index: 200;
         color: $white;
         font-size: 1.2rem;
         opacity: 1;
   		@include tablet {
            display: block;
            font-size: 1.3rem;
   		}		
   		@include desktop {
            font-size: 1.4rem;
   		}	
   		@include ie {
   		}	
   		@include edge {
   		}
   	}
	}	
   &-grad {
      display: none;
   	@include size(100%, 100%);	
		@include absolute(left 0 top 0);
		opacity: 1;
		z-index: 2;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,222222+100&0+60,0.7+100 */
      background: -moz-linear-gradient(top,  rgba(34,34,34,0) 0%, rgba(34,34,34,0) 20%, rgba(34,34,34,0.8) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(34,34,34,0) 0%,rgba(34,34,34,0) 20%,rgba(34,34,34,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(34,34,34,0) 0%,rgba(34,34,34,0) 20%,rgba(34,34,34,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00222222', endColorstr='#b3222222',GradientType=0 ); /* IE6-9 */
      @include tablet {
         display: block;
         background: -moz-linear-gradient(top,  rgba(34,34,34,0) 0%, rgba(34,34,34,0) 60%, rgba(34,34,34,0.7) 100%); /* FF3.6-15 */
         background: -webkit-linear-gradient(top,  rgba(34,34,34,0) 0%,rgba(34,34,34,0) 60%,rgba(34,34,34,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
         background: linear-gradient(to bottom,  rgba(34,34,34,0) 0%,rgba(34,34,34,0) 60%,rgba(34,34,34,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00222222', endColorstr='#b3222222',GradientType=0 ); /* IE6-9 */
      }
   }
   &-photo {
      width: 100%;
      top: 0px;
      left: 0px;
      position: relative;
      overflow: hidden;
      transition: $slow;
      @include tablet {
   		@include size(100%, 100%);	
   		@include absolute(left 0 top 0);
   		opacity: 1;
   		z-index: 1;
         overflow: visible;
      }
		&-pic {
         @include tablet {
   			@include size(100%);	
   			@include absolute(left 0 top 0);
   			object-fit: cover;
   			overflow: hidden;	
            transition: $slow;
         }
         &.opacity-30 {
            opacity: .3 !important;
         }
         &.opacity-50 {
            opacity: .5 !important;
         }
         &.opacity-70 {
            opacity: .7 !important;
         }
         &.opacity-100 {
            opacity: 1.0 !important;
         }
      }
		&-img {
			@include size(100%);	
			object-fit: cover;
         transition: $veryslow;
         display: block;
         opacity: 0;
         &.reveal {
            opacity: 1.0;
         }
		}				
	}
   &:hover {
   }
   &:hover .hero-item-container {
      background: $black;
   }
   &:hover .hero-item-photo-pic {
      opacity: 1.0 !important;
   }
   &:hover .hero-item-photo-img {
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -o-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1); 
   }
   &:first-child {
      height: 450px;
      @include tablet {
   		height: 500px;
   	}
      @include tablet-wide {
         height: 600px;
         -webkit-box-shadow: 0 0 40px 0 rgba(0,0,0,.85);
         box-shadow: 0 0 40px 0 rgba(0,0,0,.85); 
         z-index: 200;
      }
      @include desktop {
   		height: 750px;
   	}
      @include md {
      	height: 800px;
   	}
      @include lg {
         -webkit-box-shadow: none;
         box-shadow: none;          
      }
      &::after {
         @include tablet {
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            right: -1px;
            z-index: 300;
            background: $white; 
         }
         @inlclude lg {
            display: none;
         }
      } 
      & .hero-item-grad {
         display: block;
      }
      & .hero-item-photo {
      	@include size(100%, 100%);	
   		@include absolute(left 0 top 0);
   		opacity: 1;
   		z-index: 1;
         overflow: visible;
      }
      & .hero-item-container {
         position: absolute;
         bottom: 2rem;
      }
      & .hero-item-blurb {
         padding: 0 2rem;
      }
      & .hero-item-blurb-pre-headline {
         font-size: 1.0rem;
      }
      & .hero-item-blurb-headline {
         font-size: 2.0rem;
      }
   }
   &:nth-child(2) {
      padding: 2rem;
      @include tablet {
         padding: 0;
         background: $clear;
      }
      & .hero-item-photo {
         border: 1px solid white;
         border-bottom: 0px;
      }
      & .hero-item-container {
         border: 1px solid white;
         border-top: 0px;
      }
      & .hero-item-photo-pic {
         opacity: 1.0;
      }
   }
   &:last-child {
      z-index: 100;
      @include tablet {
         -webkit-box-shadow: none;
         box-shadow: none;          
      }
   }
}
