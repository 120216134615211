.lite {
   & a {
      display: block;
      background: $white;
      padding: .8rem;
      text-align: center;
      font-size: .70rem;
      color: $black;
      text-decoration: underline;
      transition: $slow;
      &:hover {
         background: $black;
         color: $white;
      }
      @include phone {
         font-size: .75rem;      
      }
      @include tablet {
         font-size: 1rem;      
      }
      @include tablet-wide {
         display: none;      
      }
   }
}
